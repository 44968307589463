import React, { useEffect, useState } from 'react'
import GET_TUTOR_INFO from './getTutorInfo.graphql'
import GET_STUDENTS from './getParentProductStudents.graphql'
import LINK_STUDENT_TUTOR from './linkStudentTutor.graphql'
import UNLINK_STUDENT_TUTOR from './unlinkStudentTutor.graphql'
import { useApolloClient } from '@apollo/client';
import Loader from '../UI/Loader'
import styled from 'styled-components'
import { colors } from '../../theme';
import { Box } from 'grommet';
import Avatar from '../UI/Avatar';
import { FormAdd, FormSubtract } from 'grommet-icons'

const UserInformation = styled.div`
  display: flex;
  align-items: center;
  font-size: 23px;
  color: ${colors.indigo};
  font-weight: 500;
  > :first-child {
    margin-right: 16px;
  }
  margin-bottom: 20px;
`

const Content = styled.div`
  display: flex;
  > :first-child {
    margin-right: 30px;
  }
  > * {
    flex: 1;
    max-width: 260px;
  }
`

const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > :first-child {
    display: flex;
    align-items: center;
    > :first-child {
      margin-right: 8px;
    }
  }
  padding-bottom: 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid ${colors.lightGray};
  > :last-child {
    cursor: pointer;
    user-select: none;
  }
`

const TutorInfo = ({ _id }) => {
  const [loading, setLoading] = useState(true)
  const client = useApolloClient()
  const [user, setUser] = useState()
  const [tutorLessStudents, setTutorLessStudents] = useState([])
  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const init = async () => {
    setLoading(true)
    const { data } = await client.query({
      query: GET_TUTOR_INFO,
      variables: { _id },
      fetchPolicy: 'network-only'
    })

    const { data: studentsData } = await client.query({
      query: GET_STUDENTS,
      variables: { _id }
    })
    setUser(data.findUser)
    setTutorLessStudents(studentsData.parentProductStudents)
    setLoading(false)
  }

  const addStudent = async (student) => {
    const { data } = await client.mutate({
      mutation: LINK_STUDENT_TUTOR,
      variables: { studentId: student._id, tutorId: user._id }
    })
    if (data.linkStudentTutor) {
      setTutorLessStudents(tutorLessStudents.filter(s => s._id !== student._id))
      setUser({...user, tutoringStudents: [...user.tutoringStudents, student]})
    }
  }

  const removeStudent = async (student) => {
    const { data } = await client.mutate({
      mutation: UNLINK_STUDENT_TUTOR,
      variables: { studentId: student._id, tutorId: user._id }
    })
    if (data.unlinkStudentTutor) {
      setUser({...user, tutoringStudents: user.tutoringStudents.filter(s => s._id !== student._id) })
      setTutorLessStudents([...tutorLessStudents, student])
    }
  }

  if (loading) return <Loader />
  return <div>
    <UserInformation>
      <div>{user.name}</div>
    </UserInformation>
    <Content>
      <Box background="#fff" round="small" elevation="medium" pad="medium">
        {
          tutorLessStudents.map((student) => <User>
            <div>
              <Avatar user={student} />
              <div>{student.username}</div>
            </div>
            <FormAdd onClick={() => addStudent(student)} />
          </User>)
        }
      </Box>
      <Box background="#fff" round="small" elevation="medium" pad="medium">
        {
          user.tutoringStudents.map((student) => <User>
            <div>
              <Avatar user={student} />
              <div>{student.username}</div>
            </div>
            <FormSubtract onClick={() => removeStudent(student)} />
          </User>)
        }
      </Box>
    </Content>
  </div>
}

TutorInfo.propTypes = {

}

TutorInfo.defaultProps = {

}

export default TutorInfo
