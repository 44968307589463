import { PageProps } from 'gatsby';
import React from "react"

import TutorInfo from "../../components/TutorInfo";

const TutorPage = (props: PageProps) => {
  if (typeof window === "undefined") return null

  return <TutorInfo _id={props.params._id} />
}

export default TutorPage
